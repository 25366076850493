import { createSlice } from '@reduxjs/toolkit'

export const authSlice = createSlice({
    name: 'authentication',
    initialState: {
        isLogin: false,
        user: {}
    },
    reducers:{
        handleLogin: (state, { payload }) => {
            state.isLogin = true
            state.user = payload
        },
        handleLogout: state => {
            state.isLogin = false
            state.user = null
        }
    }
})

export const { handleLogin, handleLogout } = authSlice.actions

export default authSlice.reducer
