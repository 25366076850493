import { createSlice } from '@reduxjs/toolkit'

export const errorSlice = createSlice({
    name: 'error',
    initialState: [],
    reducers:{
        pushMessage: (state, { payload }) => {
            state = payload
            return state
        },
        decrementMessage: (state) => {
            if (state.length > 0) {
                state = [...state.slice(1, state.length)]
                return state
            }
        },
        removeMessageIndex: (state, { payload }) => {
            state = [...state.filter((_, index) => index !== payload)]
            return state
        }
    }
})

export const { 
    pushMessage,
    removeMessage,
    decrementMessage,
    removeMessageIndex
} = errorSlice.actions

export default errorSlice.reducer
