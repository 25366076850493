// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './auth'
import message from './message'

const rootReducer = {
  auth,
  navbar,
  layout,
  message
}

export default rootReducer
